angular.module('fingerink')
    .config(function config($stateProvider) {
        $stateProvider.state('public', {
            views: {
                "content": {
                    templateUrl: 'web/public/public.tpl.html',
                    controller: 'publicCtrl',
                    controllerAs: 'controller'
                }
            }
        });
    })
    .controller('publicCtrl', function($rootScope, $scope) {


    });
